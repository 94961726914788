var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "paginaLogin" } },
    [
      _c(
        "el-card",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.cargando,
              expression: "cargando"
            }
          ],
          attrs: { id: "loginCard" }
        },
        [
          _c(
            "div",
            { attrs: { id: "loginBody" } },
            [
              _c("transition", { attrs: { name: "fade-in-slow" } }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.mostrarLogo,
                        expression: "mostrarLogo"
                      }
                    ],
                    staticStyle: { display: "flex" }
                  },
                  [
                    _c("img", {
                      staticStyle: { margin: "auto" },
                      attrs: {
                        src: require("../../assets/logo.png"),
                        height: "60px"
                      }
                    })
                  ]
                )
              ]),
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: { id: "loginForm", model: _vm.form, rules: _vm.rules }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "username" } },
                    [
                      _c("el-input", {
                        ref: "username",
                        staticClass: "input-login",
                        attrs: { placeholder: "Usuario", autofocus: true },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.login($event)
                          }
                        },
                        model: {
                          value: _vm.form.username,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "username", $$v)
                          },
                          expression: "form.username"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "password" } },
                    [
                      _c("el-input", {
                        staticClass: "input-login",
                        attrs: { placeholder: "Contraseña", type: "password" },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.login($event)
                          }
                        },
                        model: {
                          value: _vm.form.password,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "password", $$v)
                          },
                          expression: "form.password"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { display: "flex" } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { margin: "auto" },
                      attrs: {
                        id: "botonIngresar",
                        type: "primary",
                        round: "30px"
                      },
                      on: { click: _vm.login }
                    },
                    [_c("span", [_vm._v("Ingresar")])]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }